@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  order: 0;
  flex-grow: 10;
  margin-inline-end: 0;
  margin-block-end: calc(-1 * #{awsui.$space-scaled-m});

  > *:not(:empty) {
    margin-inline-end: awsui.$space-scaled-m;
    margin-block-end: awsui.$space-scaled-s;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  inline-size: auto;
  max-inline-size: 728px;
}

.select-filter {
  max-inline-size: 160px;
  flex-grow: 2;
  inline-size: auto;
}

.filtering-results {
  display: block;
  margin-inline-start: 1rem;
  padding-block-end: calc(1px + #{awsui.$space-scaled-xxs});
  color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
  .input-container {
    margin-inline-end: calc(-1 * #{awsui.$space-scaled-m});
  }

  .select-filter {
    max-inline-size: none;
  }

  .input-filter {
    inline-size: 100%;
    max-inline-size: none;
  }
}