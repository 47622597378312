// Note: These styles are not necessary for AWS Consoles

@use "~@amzn/awsui-design-tokens/polaris" as awsui;

body {
  background: awsui.$color-background-layout-main;
  position: relative;
}

.custom-main-header {
  display: block;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  // corresponds to the z-index in Mezzanine
  z-index: 1000;
  margin: 0;
  background-color: #0f1b2a;
  font-family: awsui.$font-family-base;
  border-block-end: solid 1px #414d5c;
}

.screenreader-only {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}

ul.menu-list {
  block-size: 39px;
  font-size: 14px;
  padding-block: 0;
  padding-inline: 20px;

  &,
  & ul {
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
  }

  ul,
  li {
    padding: 0;
  }

  & li {
    margin: 0;
    padding-block: 0;
    padding-inline: 8px;

    border-inline-end: 2px solid #e9ebed;
    &:last-child {
      border-inline-end: none;
    }

    li {
      border-inline-end: 1px solid awsui.$color-border-control-default;
      &:first-child {
        padding-inline-start: 0;
      }
      &:last-child {
        border-inline-end: none;
        padding-inline-end: 0;
      }
    }

    > a {
      padding-block: 0;
      padding-inline: 6px;
    }

    &.title {
      margin-inline-end: auto;
      border-inline-end: none;
    }

    a,
    div,
    button,
    input,
    label {
      float: inline-start;
      color: awsui.$color-text-interactive-default;
      line-height: 16px;
    }

    #visual-refresh-toggle {
      margin-inline-end: 5px;
      margin-block-start: 1px;
    }

    a,
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.title {
      font-weight: bold;
    }

    &.selected > a,
    &.selected > button {
      font-weight: bold;
      color: awsui.$color-text-accent;
    }
  }

  > li {
    padding-block: 0;
    padding-inline: 20px;
  }

  button,
  button:focus {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  li.checkbox-item {
    display: inline-flex;
    margin-inline-end: 0;
  }

  @media only screen and (max-width: 493px) {
    padding-block: 4px;
    padding-inline: 20px;
    flex-wrap: wrap;
    block-size: fit-content;

    .title {
      flex: 1 1 100%;
      margin-block-end: 8px;
    }

    li {
      inline-size: min-content;

      button,
      a {
        text-align: left;
      }

      a {
        padding: 0;
      }
    }
  }
}

// HELPERS
.pull {
  &-right {
    float: right;
  }
  &-left {
    float: left;
  }
}

.align {
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
}

.small-font {
  font-size: 0.8em;
}
